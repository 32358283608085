<template>
    <main>
      <div class="row">
        <div class="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-sm-12 col-xs-12" v-for="(chart, i) in apply" :key="`chart_${i}`" >
          <component :ref="chart.component" :is="chart.component" v-bind="chartsData[chart.component]"></component>
        </div>
      </div>
    </main>
  </template>
  <script>
  import dayjs from "dayjs";
  import examenFisicoService from '../../../services/examenFisicoService';
  import {isEmpty} from "lodash";
  import graficasService from "@/services/graficasService";
  import Toast from '@/components/common/utilities/toast';
  import imcNinos5a19A from "../base/graficas/imcNinos5a19A/imcNinos5a19A.vue";
  import tallaParaLaEdadNinos5a19A from "../base/graficas/tallaParaLaEdadNinos5a19A/tallaParaLaEdadNinos5a19A.vue";
  import pesoParaLaEdadNinos5a10A from "../base/graficas/pesoParaLaEdadNinos5a10A/pesoParaLaEdadNinos5a10A.vue";
  import imcNinas5a19A from "../base/graficas/imcNinas5a19A/imcNinas5a19A.vue";
  import tallaParaLaEdadNinas5a19A from "../base/graficas/tallaParaLaEdadNinas5a19A/tallaParaLaEdadNinas5a19A.vue";
  import pesoParaLaEdadNinas5a10A from "../base/graficas/pesoParaLaEdadNinas5a10A/pesoParaLaEdadNinas5a10A.vue";
  
  export default {
    props: ["usuario", "idHistoria"],
    components: {
      imcNinos5a19A,
      tallaParaLaEdadNinos5a19A,
      pesoParaLaEdadNinos5a10A,
      imcNinas5a19A,
      tallaParaLaEdadNinas5a19A,
      pesoParaLaEdadNinas5a10A
    },
    data (){
      return {
        examenFisico : {},
        charts : [
          {
            name : 'IMC niños de 5 a 19 Años',
            component : 'imcNinos5a19A',
            start : 5,
            end : 19,
            gender : 'M'
          },
          {
            name : 'Talla para la edad niños de 5 a 19 Años',
            component : 'tallaParaLaEdadNinos5a19A',
            start : 5,
            end : 19,
            gender : 'M'
          },
          {
            name : 'Peso para la edad niños de 5 a 10 Años',
            component : 'pesoParaLaEdadNinos5a10A',
            start : 5,
            end : 10,
            gender : 'M'
          },
          {
            name : 'IMC niñas de 5 a 19 Años',
            component : 'imcNinas5a19A',
            start : 5,
            end : 19,
            gender : 'F'
          },
          {
            name : 'Talla para la edad niñas de 5 a 19 Años',
            component : 'tallaParaLaEdadNinas5a19A',
            start : 5,
            end : 19,
            gender : 'F'
          },
          {
            name : 'Peso para la edad niñas de 5 a 10 Años',
            component : 'pesoParaLaEdadNinas5a10A',
            start : 5,
            end : 10,
            gender : 'F'
          },
        ],
      }
    },
    async created(){
      this.LoaderSpinnerShow();
      const response = await examenFisicoService.showByHc(this.idHistoria);
      this.examenFisico = response.data;
      this.LoaderSpinnerHide();
    },  
    methods : {
      async save(){
        this.LoaderSpinnerShow();
        let charts = [];
  
        this.apply.forEach(x => {
          charts.push(this.$refs[x.component][0].export());
        });
  
        const promiseArray = charts.map(x => {
          return graficasService.store({
            id_historia : this.idHistoria,
            charts : [x],
          });
        });
        
        await Promise.all(promiseArray);
  
        /* await graficasService.store({
          id_historia : this.idHistoria,
          charts : charts
        }); */
        this.LoaderSpinnerHide();
        Toast.fire({
            icon: 'success',
            title: 'Datos guardados con exito'
        });
      }
    },
    computed: {
      edad: function() {
        const now = dayjs();
        return now.diff(this.usuario.fecha_nacimiento, "years");
      },
      edadEnMeses: function() {
        const now = dayjs();
        return now.diff(this.usuario.fecha_nacimiento, "month", true);
      },
      indice_masa_corporal (){
  
        if(isEmpty(this.examenFisico)) return 0;
  
        if(this.peso !== '' && this.talla !== ''){
          let imc = Number(this.examenFisico.peso) / (Math.pow(Number(this.examenFisico.talla/100), 2) );
          return Number(imc.toFixed(2));
        }
  
        return 0;
      },
      apply(){
        if(isEmpty(this.examenFisico)) return [];
        return this.charts.filter(x => this.edad >= x.start && this.edad <= x.end && x.gender === this.usuario.sexo);
      },
      chartsData(){
        return {
          'imcNinos5a19A' : {
            x : this.edadEnMeses,
            y : this.indice_masa_corporal
          },
          'tallaParaLaEdadNinos5a19A' : {
            x : this.edadEnMeses,
            y : this.examenFisico.talla
          },
          'pesoParaLaEdadNinos5a10A' : {
            x : this.edadEnMeses,
            y : this.examenFisico.peso
          },
          'imcNinas5a19A' : {
            x : this.edadEnMeses,
            y : this.indice_masa_corporal
          },
          'tallaParaLaEdadNinas5a19A' : {
            x : this.edadEnMeses,
            y : this.examenFisico.talla
          },
          'pesoParaLaEdadNinas5a10A' : {
            x : this.edadEnMeses,
            y : this.examenFisico.peso
          },
        }
      }
    },
  };
  </script>
  